import React, { useEffect, useState } from "react";
import client from "assets/js/commonApi";
// import Login from "./Login";

// 메인화면 [Main]
// import MainStyle from 'Components/Main/Main.module.scss'
// import React, { useState } from "react";
// import SideMenu from 'Components/SideMenu/SideMenu'
// import Context from 'Components/Context/Context'
// import Progress from 'Components/Modal/LoadingBar'; // UI확인을 위한 임시 임포트 // JE

// 추가 S
import {
  Button,
  Stack,
  Box,
  Grid,
  Typography,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// 추가 E
import BasicCard from "components/card/BasicCard";
import OutlinedCard from "components/card/OutlinedCard";
import MainTopCard from "components/viewContents/MainTopCard";
import MainBottomCard from "components/viewContents/MainBottomCard";
// import DataGridMainA from "components/grid/DataGridMainA";
// import DataGridMainB from "components/grid/DataGridMainB";
import DataGridMain from "components/grid/DataGridMain";

// 이미지
import maintopicon_01 from "../assets/images/mainImage/maintopicon_01.svg";
import maintopicon_02 from "../assets/images/mainImage/maintopicon_02.svg";
import maintopicon_03 from "../assets/images/mainImage/maintopicon_03.svg";
import maintopicon_04 from "../assets/images/mainImage/maintopicon_04.svg";
// import mainbottomicon_01
import mainbottomicon_01 from "../assets/images/mainImage/mainbottomicon_01.svg";
import mainbottomicon_02 from "../assets/images/mainImage/mainbottomicon_02.svg";
import mainbottomicon_03 from "../assets/images/mainImage/mainbottomicon_03.svg";

import { useTotalQuery } from "assets/hooks/useHome/useTotalQuery";

// import MainBottomCard from "components/viewContents/MainBottomCard";
// import maintopicon_01 from "../assets/images/mainImage/maintopicon_01.png";

// import maintopicon_01 from "../assets/images/maintopicon_01.png";
// import logo from "../assets/images/logo.svg";
// import maintopicon_01
// import logo from "../assets/images/logo.svg";
// import {
//   todoState,
//   // usersState,
//   // postCommentsState,
// } from "state/recoil/Maincoil";

const Home = () => {
  // // 누적 계약 건수 데이터
  // const [accumulateData, setAccumulateData] = useState([]);
  // // NFT 데이터
  // const [totalSumData, setTotalSumData] = useState([]);

  // // 데이터 확인용 콘솔
  // console.log("## [Home] 누적 데이터 - 확인1", accumulateData);
  // console.log("## [Home] [현재 월] 누적 데이터", accumulateData[0]);
  // console.log("## [Home] [현재 월]", accumulateData[0]?.month);
  // console.log(
  //   "## [Home] [현재 월] 누적계약건수",
  //   accumulateData[0]?.agree_count,
  // );
  // console.log("## [Home] [현재 월] 거래 데이터 용량", accumulateData[0]?.size);
  // console.log("## [Home] NFT 데이터-확인3", totalSumData);
  // console.log("## [Home] NFT [현재 월] 누적 데이터", totalSumData[0]?.token);

  const checkPopup = sessionStorage.getItem("checkPopup");
  const [openPopup, setOpenPopup] = useState(checkPopup !== "true");
  const onDissmissPopup = () => {
    sessionStorage.setItem("checkPopup", "true");
    setOpenPopup(false);
  };
  const { data: totalData } = useTotalQuery();

  // 숫자를 포맷팅하는 함수
  const formatTotalData = (num) => {
    return new Intl.NumberFormat("ko-KR").format(num);
  };

  console.log("##@@totalData", totalData);
  console.log("##@@formatTotalData", formatTotalData);
  // useEffect(
  //   (params) => {
  //     const headers = {
  //       "Content-type": "application/json",
  //       Accept: "application/json",
  //     };
  //     // 총 계약 건수/ 거래 데이터 용량/ FT 금액
  //     client
  //       .get(
  //         "/mdpt_platform/accumulate",
  //         // {
  //         //   did: did,
  //         //   contract_number: contract_number,
  //         // },
  //         { did: "" },
  //         { headers: headers },
  //       )
  //       .then((res) => {
  //         let datas = res.data.data;
  //         // 솔트의반환값을데이타스에다시 할당해준다.
  //         console.log("## Home 누적 통계 데이터", datas);
  //         datas = datas.sort(function (a, b) {
  //           return b.MONTH - a.MONTH;
  //           // setAccumulateData(datas);
  //         });
  //         // datas = datas.slice(3, 6); 7월 8월 9월 표시
  //         datas = datas.slice(0);
  //         // datas = datas.reverse();

  //         console.log("res.data.data", JSON.stringify(res.data.data));
  //         const newDatas = datas?.map((data, index) => {
  //           for (let key in data) {
  //             const lowerKey = key.toLowerCase();
  //             if (lowerKey !== key) {
  //               data[lowerKey] = data[key];
  //               delete data[key]; // 오브젝트 키 A 값 12345 빈오브젝트 만드는기능
  //             }
  //           }
  //           return {
  //             ...data,
  //             id: index,
  //           };
  //         });
  //         setAccumulateData(newDatas);
  //         console.log("## [Home] 누적 데이터 - 확인2", datas);
  //       });
  //     // NFT 금액
  //     client
  //       .post(
  //         "/mdpt_tf/select_ntf_accept",
  //         // {
  //         //   did: did,
  //         //   contract_number: contract_number,
  //         // },
  //         { did: "" },
  //         headers,
  //       )
  //       .then((res) => {
  //         const datas = res.data.data;
  //         console.log(
  //           "[통계 전체 데이터 res.data.data]",
  //           JSON.stringify(res.data.data),
  //         );
  //         const newDatas = datas?.map((data, index) => {
  //           for (let key in data) {
  //             const lowerKey = key.toLowerCase();
  //             if (lowerKey !== key) {
  //               data[lowerKey] = data[key];
  //               delete data[key]; // 오브젝트 키 A 값 12345 빈오브젝트 만드는기능
  //             }
  //           }

  //           return {
  //             ...data,
  //             id: index,
  //           };
  //         });
  //         setTotalSumData(newDatas);
  //         console.log("## [Home] NFT 데이터-확인1", datas);
  //         console.log("## [Home] [대소문자변경] NFT 데이터-확인2", newDatas);
  //       });
  //   },

  //   [],
  // );

  // 프롬프트 - 토큰
  // useEffect(() => {
  //   let access = sessionStorage.getItem("access");

  //   if (!access) {
  //     const access = prompt();
  //     sessionStorage.setItem("access", access);
  //   }
  // }, []);

  // const userData = todo.filter((user, index, callback) => {
  //   return index === callback.findIndex((kyy) => kyy.userId === user.userId);
  // });
  // let testArr = [];
  // for (let i = 0; i < userData.length; i++) {
  //   if (Number(id) === userData[i].userId) {
  //     testArr.push(userData[i]);
  //   }
  // }

  // const onChange = (event) => {
  //   setPartner(event.target.value);
  // };

  // console.log("partner2222222", partner);
  // console.log("partner", partner);
  // console.log("todo", todo);
  // console.log("users", users);

  // const [todos, setTodos] = useState([]);
  // const onChange = (event) => {
  //   setPartner(event.target.value);
  // };

  const MainTopCardContent = [
    {
      title: "총 계약 건수",
      // date: totalData[0]?.agree_count,
      date: formatTotalData(totalData?.contract_cnt),
      description: "건",
      image: maintopicon_01,
      imageLabel: "총 계약 건수 아이콘",
    },
    {
      title: "거래 데이터 용량",
      // date: accumulateData[0]?.size,
      date: formatTotalData(totalData?.upload_data_size),
      description: "Byte",
      src: "../assets/images/logo.svg",
      image: maintopicon_02,
      imageLabel: "거래 데이터 용량 아이콘t",
    },
    {
      title: "NFT 금액",
      // date: totalSumData[0]?.token,
      date: formatTotalData(totalData?.nft_amount),
      description: "개",
      image: maintopicon_03,
      imageLabel: "NFT 금액 아이콘",
    },
    {
      title: "FT 금액",
      // date: accumulateData[0]?.ft_token,
      date: formatTotalData(totalData?.ft_amount),
      description: "개",
      image: maintopicon_04,
      imageLabel: "FT 금액 아이콘",
    },
  ];

  const MainBottomCardContent = [
    {
      title: "데이터 거래",
      // date: "56",
      description: "데이터의 안전한 거래를 위한  IPFS 기반 데이터 관리",
      image: mainbottomicon_01,
      imageLabel: "총 계약 건수 아이콘",
    },
    {
      title: "안전한 계약",
      // date: "500",
      description:
        "안전한 계약을 위한 블록체인  Smart Contract 기반 계약 관리   ",
      src: "../assets/images/logo.svg",
      image: mainbottomicon_02,
      imageLabel: "거래 데이터 용량 아이콘t",
    },
    {
      title: "AI 프레임워크",
      // date: "5,000,000",
      description: "기업의 AI분석을 도와주기 위한 프레임워크 서비스 ",
      image: mainbottomicon_03,
      imageLabel: "NFT 금액 아이콘",
    },
  ];

  return (
    <>
      {/* <img src={logo} alt="dd" />
      <img src={maintopicon_01} alt="dd" /> */}
      <Box
        sx={
          {
            // backgroundColor: "green",
            // width: "100%",
            // pt: 8,
            // pb: 6,
          }
        }
      >
        {/* contentsFir S */}
        <Box
          sx={{
            backgroundColor: "#29314A",
          }}
        >
          <Container
            sx={{
              // backgroundColor: "green",
              // width: "100%",
              pt: 8,
              pb: 6,
            }}
            maxWidth="lg"
          >
            <Typography
              component="h2"
              variant="h2"
              sx={{ fontWeight: "400", color: "#fff" }}
              // align="center"

              gutterBottom
            >
              울산의
              <Typography
                component="h2"
                variant="h2"
                sx={{ fontWeight: "400", color: "#468ECD" }}
              >
                새로운 미래
                <Typography
                  component="body1"
                  variant="h2"
                  sx={{ fontWeight: "400", color: "#fff" }}
                >
                  를 위한 공간
                </Typography>
              </Typography>
            </Typography>
            {/* <img src={maintopicon_01} /> */}
            {/* <img src={maintopicon_01} alt="dd" />
            <img src={logo} alt="dd" /> */}
            <Grid container spacing={4}>
              {MainTopCardContent.map((card) => (
                <MainTopCard key={card.title} card={card} />
              ))}
            </Grid>
          </Container>
        </Box>
        {/* contentsFir E */}
        {/* contentsSec S */}
        <Box
          sx={{
            pt: "52px",
            pb: "52px",
            // flexGrow: 1,
            // backgroundColor: "#29314A",
          }}
        >
          <Container maxWidth="lg">
            {/* <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained">Main call to action</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack> */}
            <Grid container spacing={4}>
              {/* <DataGridMainA />
              <DataGridMainB /> */}
              <DataGridMain
                dataTitle="최근 계약 데이터"
                mainHomeSortOption="reg_date"
              />
              <DataGridMain dataTitle="인기 데이터" mainHomeSortOption="like" />
              {/* <OutlinedCard />
              <OutlinedCard /> */}
            </Grid>
          </Container>
        </Box>
        {/* contentsSec E */}
        {/* contentsThi S */}
        <Box
          sx={{
            backgroundColor: "#EFF2F4",
            pt: "52px",
            pb: "36px",
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h3"
              variant="h3"
              sx={{
                fontWeight: "400",
                color: "#29314a",
                textAlign: "center",
                marginBottom: "32px",
              }}
              // align="center"

              gutterBottom
            >
              데이터
              <Typography
                component="body1"
                variant="h3"
                sx={{ fontWeight: "300", color: "#282933" }}
              >
                의
              </Typography>
              <Typography
                component="body1"
                variant="h3"
                sx={{ fontWeight: "400", color: "#29314a" }}
              >
                새로운 자원
              </Typography>
              <Typography
                component="body1"
                variant="h3"
                sx={{ fontWeight: "300", color: "#282933" }}
              >
                으로 활용을 통한
              </Typography>
              <Typography
                component="body1"
                variant="h3"
                sx={{ fontWeight: "400", color: "#29314a" }}
              >
                성장
              </Typography>
            </Typography>
            {/* <Box sx={{ display: "flex", justifyContent: "center" }} spacing={2}>
              <BasicCard />
              <BasicCard />
              <BasicCard />
              <BasicCard />
            </Box> */}
            <Grid container spacing={4}>
              {MainBottomCardContent.map((card) => (
                <MainBottomCard key={card.title} card={card} />
              ))}
            </Grid>
          </Container>
        </Box>
        {/* contentsThi E */}
      </Box>
      <Dialog open={openPopup}>
        <DialogTitle>U-STC 테스트 APP 설치 안내</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onDissmissPopup}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography>
            현재 U-STC APP은 Google 및 Apple 테스트 배포 서비스를 사용하고
            있습니다.
          </Typography>
          <br />
          <Typography>
            테스트용{" "}
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              구글 플레이스토어 계정이나 Apple 계정
            </span>{" "}
            및
          </Typography>
          <Typography>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              사업자등록번호
            </span>
            를 아래 메일로 전달해주시면
          </Typography>
          <Typography>
            등록 후, APP 설치할 수 있도록 지원해 드리겠습니다.
          </Typography>
          <br />
          <Link href="mailto:bp.service@bpnsolution.com">
            bp.service@bpnsolution.com
          </Link>
          <br />
          <br />
          <Link href="./quick_guide_v1.2.pdf">사용자 퀵 가이드 v1.2</Link>
        </DialogContent>
        <DialogContent>
          <Typography>감사합니다.</Typography>
          <br />
          <Typography>(주)비피앤솔루션</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onDissmissPopup}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/* <div>홈 Home</div>
      <Typography variant="h2" component="h2">
        계약관리
      </Typography> */}
      {/* <Login /> */}
    </>
  );
};

export default Home;
